import React,{useEffect,useState} from "react";


import { useNavigate,useLocation, useParams } from "react-router-dom";

import BreadCrumbs from "../../components/BreadCrumbs";

import Loading from '../../components/Loading'
//import './detalhe.css';
import img_exames from '../../images/avaliacao/exames.jpg';
import api,{apiEndpoint,siteURL,siteURLShare, mseErroComunicacao} from "../../services/api";

import Erro from '../../pages/Erro'
import img_partilhar from '../../images/svg/partilhar.svg';

import {Helmet} from "react-helmet-async";

//import Share from "../../components/Share";
import image_default_contactos_associacao_pais from '../../images/images_default/associacao_pais_309x800.jpg';
import { toast } from 'react-toastify';
export default function ItemDealhe(params){
    const { slug } = useParams();
    const location=useLocation();
    const navigate=useNavigate();
    const [breadCrumbsData,setBreadCrumbsData]= useState([
        {
            nome: "Home",
            url: "/"
        }
        
    ]);

    
    const[imageDefault,setImageDefault]=useState(null);
    const [data,setData]=useState([]);
    const[loading,setLoading]=useState(true)
    const[error404,setError404]=useState(false)
    
    //para o share button
    //const[modalShareShow,setModalShareShow]=useState('');
    //const[modalSharedisplay,setModalShareDisplay]=useState('none');
    //const[URLShare,setURLShare]=useState(siteURL+location.pathname);



      useEffect(() => {
         
        async function loadData() {
            //console.log(location.pathname)
            
            let apiurl='';
            if(location.pathname=='/associacaopais/contactos-das-associacoes-de-pais'){
                setImageDefault(image_default_contactos_associacao_pais);
            }else{
                setImageDefault(null)
            }
            apiurl=`item${location.pathname}`
            
            //const response = 
            await api.get(apiurl).then(response=>{
                if(response.data===null || (typeof(response.data)=='object' && response.data.status_code===404 )){
                
                    setError404(true);
                    
                }
                else if (response.data.length > 0) {
                    //1 NextPrevLink
                    setData(response.data[0]);
    
                    //console.log("data:",response.data)
                    
                   //2
                   setBreadCrumbsData(
                    [{
                        nome: "Home",
                        url: "/"
                    },
                    {
                        nome: "Alunos",
                        url: null
                    },
                    {
                        nome:response.data[0].title,
                        url: response.data[0].url
                    }             
                    ]);
                }
                setLoading(false);
            }).catch(err=>{
                //toast.error(mseErroComunicacao);
                setTimeout(function(){
                    navigate("/erro-comunicacao");
                },5000);
            });
            //console.log("response",response,typeof(response.data[0]) )
            

            
        }

        loadData();
        

        //console.log(data)
    }, [slug]);
    

    if(error404 ===true){
       
        return(
            <>
            <Erro></Erro>
            </>
        );
    }

function tratadata(data){
    var options = {  day:'numeric',month: 'long',year:'numeric'};
    var date  = new Date(data);
    let data_str =date.toLocaleDateString("pt-pt",options).toString().replace(".","");
    options = {  month: 'long'};
    var mes1=date.toLocaleDateString("pt-pt",options).toString().replace(".","");
    var mes2=mes1.charAt(0).toUpperCase()+mes1.slice(1);
    //console.log("mes2=",mes2)
    
    data_str=data_str.replace(mes1,mes2);
     return data_str
}
    return(
<section className ="content">   
{loading===true && (<Loading></Loading>)} 

{loading===false && ( 
   <>
<Helmet>
    <title>{`Agrupamento de Escolas de Monte da Ola ${'>'} ${data.title}`}   </title>
    <meta name="description" content={`${data.body.replace(/<[^>]+>/g, '').substring(0,200)}`}/>
    <meta name="keywords" content="Website Agrupamento de Escolas de Monte da Ola"/>
    {/*facebook*/}
    <meta name="og:title" content="Agrupamento de Escolas de Monte da Ola"/>
    <meta name="og:url" content={siteURLShare+location.pathname} />
    <meta name="og:description" content={`${data.body.replace(/<[^>]+>/g, '').substring(0,200)}`}/>
    {data.image!==null &&(
        <meta name="og:image" content={data.image}/>
    )}
    {data.image!==null &&(
        <meta name="twitter:image" content={data.image}/>
    )}

    {data.image===null && imageDefault!==null && (
        <meta name="og:image" content={imageDefault}/>
    )}
    {data.image===null && imageDefault!==null &&(
        <meta name="twitter:image" content={imageDefault}/>
    )}
    <meta name="og:type" content="website"/>
    {/*twitter*/}
    <meta name="twitter:card" content="Agrupamento de Escolas de Monte da Ola"/>
    <meta name="twitter:title" content="Website do Agrupamento de Escolas de Monte da Ola"/>
    <meta name="twitter:description" content={`${data.body.replace(/<[^>]+>/g, '').substring(0,200)}`}/>
    <meta name="twitter:site" content={siteURLShare+location.pathname} />
    
    <meta name="twitter:creator" content="Agrupamento de Escolas de Monte da Ola"/>
</Helmet>


   <div className="container detalhe">    
       <BreadCrumbs data={breadCrumbsData}/>
    </div>
    {/* Titulo */}
    <div className="container detalhe">
        <div className="row ligacao justify-content-left">
            <div className="col-12">
                <h1>{data.title}</h1>
                {params.nodata!==true && (
                    <p>{tratadata(data.publishDate)}</p>
                )}               
                <div className="row justify-content-left">
                    <div className="col-2">
                        <hr/>
                    </div>    
                </div>
            </div>
        </div>
    </div>

    {/* corpo detalhe */}
    <div className="container detalhe">
        <div className="row">
            <div className="col-12 col-sm-12 col-md-8 col-lg-8 artigo-info" dangerouslySetInnerHTML={{ __html:data.body}}>
                {/*corpo de texto do item inicio*/}
                
                 {/*corpo de texto do item fim*/}  
                               
            </div>
            {/*img do item */}
            
                <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="image-artigo">
                    {data.image!== null && data.image.length>0 &&(
                        <img src={data.image}  className="d-block w-100" alt={data.title} />
                    )}
                    {data.image=== null && imageDefault!==null &&(
                        <img src={imageDefault}  className="d-block w-100" alt={data.title} />
                    )}
                    </div>
                </div>
            
            
            
            <div className="col-12 col-sm-12 justify-content-center" style={{display:"flex",marginTop:"4rem"}}>
                <button id="btn-voltar1" type="button" style={{display:"flex"}} className="btn btn-voltar" onClick={(e) => { navigate(-1); }}>
                    Voltar
                </button>
            </div>
        </div>
        
    </div>
</>
    )}
</section>
    );
}