import React,{useEffect,useState} from "react";

import './zona5noticiasanuncios.css';

import {LinkLerMais,ButtonkLerMais} from "../../../../styles";
import { Link, useNavigate } from "react-router-dom";

import img_etwinning from '../../../../images/noticias/etwinning.jpg';
import img_diasaudemental from '../../../../images/noticias/diasaudemental.jpg';
import img_diacontrasida from '../../../../images/noticias/diacontrasida.jpg';

import img_reinicioaulas from '../../../../images/informacao/reinicioaulas.jpg';
import img_contrato from '../../../../images/informacao/contrato.jpg';
import img_lista from '../../../../images/informacao/lista.jpg';

//imagens default:
import img_list_noticias from '../../../../images/images_default/placeholder_noticias_392x350.jpg';
import img_list_concurso from '../../../../images/images_default/placeholder_concursos_391x350.jpg';
import img_list_avaliacao from '../../../../images/images_default/placeholder_avaliacao_392x350.jpg'

import overlay from '../../../../images/overlay.png';
import api,{apiEndpoint,siteURL,mseErroComunicacao} from "../../../../services/api";

import Loading from "../../../../components/Loading";

import { toast } from 'react-toastify';
export default function Zona5NoticiasAnuncios(){
    const navigate= useNavigate();

    const [data,setData]=useState([]);
    const[loading,setLoading]=useState(true)
      useEffect(() => {

        async function loadData() {

            await api.get('/pagina/home/noticias-anuncios').then(response=>{
                if (response.data.length > 0) {
                    //tentou aceder com um id que nao existe, navegue para a home
                    console.log(response.data)
                    setData(response.data);
                }
                setLoading(false);
            }).catch(err=>{
                //toast.error(mseErroComunicacao);
                setTimeout(function(){
                    navigate("/erro-comunicacao");
                },5000);
            });
            //debugger;
            //console.log(response.data)
            
        }

        loadData();
    }, []);

    if(loading===true){
        return(
<section className="noticias-anucios">
        {/*<!-- Noticias e Anuncios -->*/}
        <div className="container">
            <div className="row ligacao justify-content-center">
                <div className="col-12">
                <Loading/>
            </div>
        </div>
    </div>
</section>
        );

    }


    return(
<section className="noticias-anucios">
        {/*<!-- Noticias e Anuncios -->*/}
        <div className="container main-title-home">
            <div className="row ligacao justify-content-center">
                <div className="col-12">
                    <h1>Notícias e Anúncios</h1>
                    <div className="row justify-content-center">
                        <div className="col-2">
                            <hr />
                        </div>                            
                    </div>  
                </div>
            </div>
        </div>
        <div className="container">
            <div className="row info">
                    {data.map((item, index) => {

                        let stripedHtml = item.body.replace(/<[^>]+>/g, '');
                        if(stripedHtml.length>150)stripedHtml=stripedHtml.substring(0,150)+"...";
                        else{
                            stripedHtml=stripedHtml.substring(0,150)
                        }   
                        
                        let imagem_utiliar_lst=item.image_lst;
                        if(imagem_utiliar_lst===null || imagem_utiliar_lst === ""){
                            switch(item.type){
                                case 'noticia':
                                    imagem_utiliar_lst= img_list_noticias;
                                    break;
                                case 'informacao':
                                    imagem_utiliar_lst= img_list_noticias;
                                    break;
                                case 'concurso':
                                    imagem_utiliar_lst= img_list_concurso;
                                    break;
                                case 'avaliacao-externa':
                                    imagem_utiliar_lst = img_list_avaliacao;
                                    break;
                            }
                        }

                        return (
                            <div className="col-12 col-sm-6 col-md-4 col-lg-4 card-col" key={item.id}>
                                <div className="card-thin" onClick={(e)=>{e.preventDefault();navigate(item.url);}}>
                                    <div className="img-over img-fluid">
                                        <img className="card-img-top" 
                                        style={{maxHeight: "21.875rem",height: "100%" }} 
                                        src={imagem_utiliar_lst} alt="Card image cap" />
                                        <div className="overlay-img">
                                            <img className="card-img-top" src={overlay} alt="Card image cap" />
                                        </div>
                                    </div>
                                    <div className="card-body news">
                                        <b className="card-title">{item.type==='noticia'?"Notícia"
                                                                            :item.type==='informacao'?"Informação"
                                                                            :item.type==='avaliacao-externa'?"Avaliação Externa"
                                                                            :item.type==='concurso'?'Concurso':''
                                                                            }</b>
                                        <h5 className="card-title">{item.title}</h5>
                                        <p className="card-text" >{stripedHtml}</p>
                                        <div className="row justify-content-center">
                                            <div className="col-12 lermais">
                                                <LinkLerMais href={item.url} className="btn btn-new1"><h4>Ler Mais</h4></LinkLerMais>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>);

                    })} 
                    
            </div>
        </div>

    
    
        <div className="container-fluid">
            <div className="row justify-content-center">
                <div className="col-6 col-sm-2 vermais">
                    <a href="/noticia-anuncio"><ButtonkLerMais type="button" className="btn btn-big-lermais">Ver Mais</ButtonkLerMais></a>
                </div>
            </div>
        </div>    
</section>
    );

}