import axios from 'axios';

export const ambiente = "prod";//dev ou prod
//import { useState } from 'react';

var apiEndpoint_;
var siteURL_;
var siteURLShare_;

if (ambiente === "dev") {

    apiEndpoint_ = 'http://www.teste:8000/' //'http://www.teste.local:8000/';
    siteURL_ = 'http://localhost:3000/';
    siteURLShare_ = 'http://localhost:3000';

} else if (ambiente === "prod") {

    apiEndpoint_ = 'http://webapi.oxygeniu.eu/';
    siteURL_ = 'http://oxygeniu.eu/';
    siteURLShare_ = 'http://oxygeniu.eu';
}

export var apiEndpoint = apiEndpoint_;
export var siteURL = siteURL_;
export var siteURLShare = siteURLShare_;

const api = axios.create({

    baseURL: apiEndpoint

});

//export const [redirected,setRedirected]=useState(false)



export const mseErroComunicacao = "Ocorreu um erro na comunicação com o servidor.Verifique a sua ligação à Internet";

export default api;





