import React,{useEffect,useState} from "react";

import TinySlider from "tiny-slider-react";
import 'tiny-slider/dist/tiny-slider.css';


import './zona2actividadestinyslider.css';

import overlay from '../../../../images/overlay.png';
import overlay2 from '../../../../images/overlay2.png';

import erasmusplus from '../../../../images/actividades/erasmusplus.jpg'
import festival_oto from '../../../../images/actividades/festival_oto.jpg'
import festival_oto_2 from '../../../../images/actividades/festival_oto_2.jpg'
import geracaozero from '../../../../images/actividades/geracaozero.jpg'
import geracaozero2 from '../../../../images/actividades/geracaozero2.jpg'


import folha_navegacao_esquerda from '../../../../images/svg/folha_navegacao_esq.svg';
import folha_navegacao_direita from '../../../../images/svg/folha_navegacao_dir.svg'; 

import {LinkLerMais} from "../../../../styles";

import api,{apiEndpoint,mseErroComunicacao} from "../../../../services/api";

import Loading from "../../../../components/Loading";

import image_lst from '../../../../images/images_default/placeholder_atividades_392x350.jpg'

import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";


export default function Zona2ActividadeTinySlider(){
    const navigate=useNavigate();
    const [data,setData]=useState([]);
    const[loading,setLoading]=useState(true)
    const [settings,setSettings] = useState({
        lazyload: true,
        nav: false,
        mouseDrag: true,
        responsive: {
            "350": {
              items: 2
            },
            "500": {
                items: 2
              },
            "800": {
                items: 4
              }
          },
          gutter: 32,
          controlsText:[`<img src="${folha_navegacao_esquerda}" />`,`<img src="${folha_navegacao_direita}" />`]

      });
      useEffect(() => {

        async function loadData() {

            //const response = 
            await api.get('pagina/active/atividade').then(response=>{
                if (response.data.length > 0) {
                    //tentou aceder com um id que nao existe, navegue para a home
                    setData(response.data);
                }
                setLoading(false);
            }).catch(err=>{
                //toast.error(mseErroComunicacao);
                setTimeout(function(){
                    navigate("/erro-comunicacao");
                },5000);
            });
            //debugger;
            //console.log(response.data)
            
        }

        loadData();
    }, []);

    if(loading===true){
        return(
<section>
    <div className="container informacoes">
        <div className="row justify-content-center">
            <div className="col-12">
                <Loading/>
            </div>
        </div>
    </div>
</section>
        );

    }



      return(
<section>
    <div className="container informacoes main-title-home">
        <div className="row justify-content-center">
            <div className="col-12">
                <h3>Atividades{loading===true && ( "...Loading ")}</h3>
                <div className="row justify-content-center">
                    <div className="col-2">
                        <hr/>
                    </div>    
                </div>
            </div>
        </div>
    </div>          
<div className="container informacoes">
   <div className="row">
       <div className="col-12">
                     
            <TinySlider settings={settings}>
            {data.map((item,index)=>{
                           
                            var options = {  month: 'short'};
                            var month  = new Date(item.publishDate);

                            let mes =month.toLocaleDateString("pt-pt",options).toString().replace(".",""); 
                            
                            var options = {  day: 'numeric'};
                            var day  = new Date(item.publishDate);
                            let dia =month.toLocaleDateString("pt-br",options);

                            dia.length==1?dia="0"+dia:dia=dia;
                            let data=dia+" "+mes
                            //console.log(today.toLocaleDateString("en-US", options)); // Saturday, September 17, 2016
                            //console.log(today.toLocaleDateString("hi-IN", options));´
                            let stripedHtml = item.body.replace(/<[^>]+>/g, '');
                return (
                    <div className="col slide-contain" onClick={(e)=>{navigate(item.url)}}style={{ cursor:"pointer",position: "relative"}} key={index}>
                        <div className="row justify-content-end" style={{ paddingRight: "0.7rem" }}>
                            <div className="col-1 data">
                                <p>{data}</p>
                            </div>
                        </div>
                        <div className="image-over">
                            <img className="slide-image-over" src={item.image_lst===null? image_lst : item.image_lst} alt="slide-img" />
                            <div className="slide-over">
                                <img className="slide-image-over" src={overlay} alt="slide-img" />
                            </div>
                        </div>
                        <div className="slide-info">
                            <h5 className="slide-title">{item.title}</h5>
                            <p className="slide-text" style={{}} dangerouslySetInnerHTML={{ __html: stripedHtml.substring(0,100)+"..." }}></p>

                        </div>
                        <div className="row justify-content-center">
                            <div className="col-sm-12 lermais2">
                                <LinkLerMais href={item.url} className="btn btn-new2"><h4>Ler Mais</h4></LinkLerMais>
                            </div>
                        </div>
                    </div>
                );
            })}    
            
            
                
            </TinySlider>
        </div>
    </div>
</div>
</section>
      );

}

