import React, { useState, useEffect } from "react";

import BreadCrumbs from "../../../components/BreadCrumbs";

import { LinkLerMais, ButtonkLerMais } from "../../../styles";

import img_etwinning from "../../../images/noticias/etwinning.jpg";
import overlay from "../../../images/overlay.png";

//import './avaliacaoexternalistagem.css';
import Loading from "../../../components/Loading";

import api, {
  apiEndpoint,
  siteURL,
  siteURLShare,
  mseErroComunicacao,
} from "../../../services/api";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";

import image_default from "../../../images/images_default/placeholder_noticias_309x470.jpg";
import { toast } from "react-toastify";

//imagens default:
import img_list_noticias from "../../../images/images_default/placeholder_noticias_392x350.jpg";
import img_list_concurso from "../../../images/images_default/placeholder_concursos_391x350.jpg";
import img_list_avaliacao from '../../../images/images_default/placeholder_avaliacao_392x350.jpg'

export default function NoticiaAnuncioListagem() {
  const location = useLocation();
  const navigate = useNavigate();
  const [tituloPrincipal, setTituloPrincipal] = useState("");
  const [breadCrumbsData, setBreadCrumbsData] = useState([
    {
      nome: "Home",
      url: "/",
    },
    {
      nome: "Notícias e Anúncios",
      url: "/noticia-anuncio",
    },
  ]);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadData() {
      let apiEndPoint = "pagina/noticias-anuncios/all";

      setTituloPrincipal("Notícias e Anúncios");
      console.log(location.pathname)
      if (location.pathname === "/concursos") {
        //alert(location.pathname)
        apiEndPoint = "/pagina/active/concurso";
        setTituloPrincipal("Concursos");
      }

      //const response =
      await api
        .get(apiEndPoint)
        .then((response) => {
          //debugger;
          //console.log(response.data)
          if (response.data.length > 0) {
            //tentou aceder com um id que nao existe, navegue para a home
            setData(response.data);

            if (location.pathname === "/concursos") {
              setBreadCrumbsData([
                {
                  nome: "Home",
                  url: "/",
                },
                {
                  nome: "Concursos",
                  url: "/concursos",
                },
              ]);
            }
          }
          setLoading(false);
        })
        .catch((err) => {
          //toast.error(mseErroComunicacao);
          setTimeout(function () {
            navigate("/erro-comunicacao");
          }, 5000);
        });
    }

    loadData();
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="content">
      <div className="container listagem">
        <BreadCrumbs data={breadCrumbsData} />
      </div>
      {/* Titulo */}
      {loading === false && (
        <div className="container listagem">
          <div className="row ligacao justify-content-center">
            <div className="col-12">
              <h1>{tituloPrincipal}</h1>
              <div className="row justify-content-center">
                <div className="col-2">
                  <hr />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Listagem */}
      <div className="container listagem">
        <div className="row info">
          {loading === true && (
            <div className="col-12">
              <Loading></Loading>
            </div>
          )}

          {loading === false && (
            <Helmet>
              <title>
                {`Agrupamento de Escolas de Monte da Ola ${">"} Listagem de Noticias e Anúncios`}{" "}
              </title>

              <meta
                name="keywords"
                content={`Website Agrupamento de Escolas de Monte da Ola, Noticias e Anúncios`}
              />
              {/*facebook*/}
              <meta
                name="og:title"
                content={`Agrupamento de Escolas de Monte da Ola ${">"} Noticias e Anúncios`}
              />
              <meta name="og:url" content={siteURLShare + location.pathname} />

              <meta name="og:type" content="website" />
              {/*twitter*/}
              <meta
                name="twitter:card"
                content={`Agrupamento de Escolas de Monte da Ola ${">"} Noticias e Anúncios`}
              />
              <meta
                name="twitter:title"
                content={`Agrupamento de Escolas de Monte da Ola ${">"} Noticias e Anúncios`}
              />

              <meta
                name="twitter:site"
                content={siteURLShare + location.pathname}
              />

              <meta
                name="og:description"
                content={`Listagem de Noticias e Anúncios `}
              />
              <meta
                name="description"
                content={`Listagem de Noticias e Anúncios `}
              />
              <meta
                name="twitter:description"
                content={`Listagem de Noticias e Anúncios`}
              />

              {data.image !== null && (
                <meta name="twitter:image" content={data.image} />
              )}
              {data.image !== null && (
                <meta name="og:image" content={data.image} />
              )}
              {data.image === null && (
                <meta name="twitter:image" content={image_default} />
              )}
              {data.image === null && (
                <meta name="og:image" content={image_default} />
              )}
              <meta
                name="twitter:creator"
                content="Agrupamento de Escolas de Monte da Ola"
              />
            </Helmet>
          )}

          {loading === false &&
            data.map((item, index) => {
              let stripedHtml = item.body.replace(/<[^>]+>/g, "");
              if (stripedHtml.length > 85) {
                stripedHtml = stripedHtml.substring(0, 85) + "...";
              } else {
                stripedHtml.substring(0, 85);
              }

              let imagem_utiliar_lst = item.image_lst;
              if (imagem_utiliar_lst === null || imagem_utiliar_lst === "") {
                switch (item.type) {
                  case "noticia":
                    imagem_utiliar_lst = img_list_noticias;
                    break;
                  case "informacao":
                    imagem_utiliar_lst = img_list_noticias;
                    break;
                  case "concurso":
                    imagem_utiliar_lst = img_list_concurso;
                    break;
                  case "avaliacao-externa":
                    imagem_utiliar_lst = img_list_avaliacao;
                    break;
                }
              }

              return (
                <div
                  className="col-12 col-sm-6 col-md-6 col-lg-4 card-col"
                  key={index}
                >
                  <div
                    className="card-thin"
                    onClick={(e) => {
                      e.preventDefault();
                      {location.pathname === '/concursos' ? navigate(item.url_concurso):navigate(item.url);}
                    }}
                    
                  >
                    <div
                      className="img-over"
                      style={
                        {
                          /*maxHeight: "21.875rem", height: "100%"*/
                        }
                      }
                    >
                      <img
                        className="card-img-top"
                        src={imagem_utiliar_lst}
                        alt="Card image cap"
                      />
                      <div className="overlay-img">
                        <img
                          className="card-img-top"
                          src={overlay}
                          alt="Card image cap"
                        />
                      </div>
                    </div>
                    <div className="card-body news" style={{ height: "270px" }}>
                      <b className="card-title">
                        {item.type === "noticia"
                          ? "Notícia"
                          : item.type === "informacao"
                          ? "Informação"
                          : item.type==='avaliacao-externa'
                          ? "Avaliação externa"
                          :item.type === "concurso"
                          ? "Concurso"
                          : ""}
                      </b>
                      <h5 className="card-title">{item.title}</h5>

                      <p className="card-text">{stripedHtml}</p>
                      <div className="row justify-content-center">
                        <div className="col-12 lermais">
                          {location.pathname === "/concursos" && (
                            <LinkLerMais
                              href={item.url_concurso}
                              className="btn btn-new1"
                            >
                              <h4>Ler Mais</h4>
                            </LinkLerMais>
                          )}
                          {location.pathname !== "/concursos" && (
                            <LinkLerMais
                              href={item.url}
                              className="btn btn-new1"
                            >
                              <h4>Ler Mais</h4>
                            </LinkLerMais>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>

      <div className="container detalhe">
        <div className="row justify-content-center">
          <div
            className="col-12 col-sm-12 justify-content-center"
            style={{ display: "flex", marginTop: "4rem" }}
          >
            <button
              id="btn-voltar1"
              type="button"
              style={{ display: "flex", marginLEft: "40%" }}
              className="btn btn-voltar"
              onClick={(e) => {
                navigate(-1);
              }}
            >
              Voltar
            </button>
          </div>
        </div>
      </div>
      {/*}<div className="container-fluid">
            <div className="row justify-content-center">
                <div className="col-6 col-sm-2 vermais">
                    <a href="noticias.html"><ButtonkLerMais type="button" className="btn btn-big-lermais">Ver Mais</ButtonkLerMais></a>
                </div>
            </div>
        </div> 
    */}
    </section>
  );
}
