import React, { useState,useEffect }  from "react";
import BreadCrumbs from "../../../components/BreadCrumbs";

import { useLocation, useNavigate } from "react-router-dom";

import {LinkLerMais,ButtonkLerMais} from "../../../styles";

import img_default from '../../../images/images_default/exames.jpg';
import overlay from '../../../images/overlay.png';

import './avaliacaoexternalistagem.css';
import Loading from '../../../components/Loading';

import api,{apiEndpoint,siteURL,siteURLShare, mseErroComunicacao} from "../../../services/api";


import {Helmet} from "react-helmet-async";
import { toast } from 'react-toastify';

export default function AvaliacaoExternaListagem(){

    const location= useLocation();
    const navigate= useNavigate();
    const [breadCrumbsData,setBreadCrumbsData]= useState([
        {
            nome: "Home",
            url: "/"
        },
        {
            nome:"Alunos",
            url:null
        },
        {
            nome:"Avaliação Externa",
            url: "/avaliacao-externa"
        }
    ])

    const [data,setData]=useState([]);
    const[loading,setLoading]=useState(true)
    
      useEffect(() => {

        async function loadData() {

            const response = 
            await api.get('pagina/active/avaliacao-externa').then(response=>{
                if (response.data.length > 0) {
                    //tentou aceder com um id que nao existe, navegue para a home
                    setData(response.data);
                }
                setLoading(false);
            }).catch(err=>{
                toast.error(mseErroComunicacao);
                setTimeout(function(){
                    navigate("/erro-comunicacao");
                },5000);    
            });
            //debugger;
            //console.log(response.data)
            
        }

        loadData();
    }, []);
    return(
<section className ="content">
      
    <div className="container listagem">    
        <BreadCrumbs data={breadCrumbsData}/>
    </div>
    {/* Titulo */}
    <div className="container listagem">
        <div className="row ligacao justify-content-center">
            <div className="col-12">
                <h1>Avaliação Externa</h1>
                <div className="row justify-content-center">
                    <div className="col-2">
                        <hr/>
                    </div>    
                </div>
            </div>
        </div>
    </div>

    {/* Listagem */}
    <div className="container listagem">
        <div className="row info">
            
            {loading===true &&(
                <div className="col-12">
                    <Loading></Loading>
                </div>
            )}
            {loading===false &&(
                <>
                        <Helmet>
                            <title>{`Agrupamento de Escolas de Monte da Ola ${'>'} Avaliação Externa`}</title>
                            <meta name="description" content={`Agrupamento de Escolas de Monte da Ola ${'>'} Avaliação Externa`}/>
                            <meta name="keywords" content={`Website do Agrupamento de Escolas de Monte da Ola,Avaliação Externa`}/>
                            {/*facebook*/}
                            <meta name="og:title" content={`Avaliação Externa`}/>
                            <meta name="og:url" content={siteURLShare+location.pathname} />
                            <meta name="og:description" content={`Agrupamento de Escolas de Monte da Ola ${'>'} Avaliação Externa`}/>
                            <meta name="og:image" content={data[0].image}/>
                            <meta name="og:type" content="website"/>
                            {/*twitter*/}
                            <meta name="twitter:card" content="Agrupamento de Escolas de Monte da Ola"/>
                            <meta name="twitter:title" content={`Avaliação Externa`}/>
                            <meta name="twitter:description" content={`Agrupamento de Escolas de Monte da Ola ${'>'} Avaliação Externa`}/>
                            <meta name="twitter:site" content={siteURLShare+location.pathname}/>
                            <meta name="twitter:image" content={data[0].image}/>
                            <meta name="twitter:creator" content="Agrupamento de Escolas de Monte da Ola"/>
                        </Helmet>  
                        {data.map((item, index) => {
                            let stripedHtml = item.body.replace(/<[^>]+>/g, '');
                            if(stripedHtml.length>75)stripedHtml=stripedHtml.substring(0,75)+"...";
                            else{
                                stripedHtml=stripedHtml.substring(0,75)
                            }
                            return (
                                <div className="col-12 col-sm-6 col-md-6 col-lg-4 card-col" key={index} onClick={(e)=>{navigate(item.url)}}>
                                    <div className="card-thin">
                                        <div className="img-over" style={{/*maxHeight: "21.875rem", height: "100%"*/ }}>
                                            <img className="card-img-top" style={{/*maxHeight: "21.875rem", height: "100%"*/ }} src={(item.image_lst===null || item.image_lst === "")? img_default : item.image_lst} alt="Card image cap" />
                                            <div className="overlay-img">
                                                <img className="card-img-top" src={overlay} alt="Card image cap" />
                                            </div>
                                        </div>
                                        <div className="card-body news" style={{ height: "270px" }}>
                                            <h5 className="card-title">{item.title}</h5>
                                            <p className="card-text">{stripedHtml}</p>
                                            <div className="row justify-content-center">
                                                <div className="col-12 lermais">
                                                    <LinkLerMais href={item.url} className="btn btn-new1"><h4>Ler Mais</h4></LinkLerMais>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                 </>
            )}
            
        </div>
    </div>
    <div className="container detalhe">
        <div className="row justify-content-center">
            <div className="col-12 col-sm-12 justify-content-center" style={{display:"flex",marginTop:"4rem"}}>
                <button id="btn-voltar1" type="button" style={{display:"flex",marginLEft:"40%"}} className="btn btn-voltar" onClick={(e) => {navigate(-1); }}>
                Voltar
                </button>
            </div>

        </div>
    </div>
    {/*}<div className="container-fluid">
            <div className="row justify-content-center">
                <div className="col-6 col-sm-2 vermais">
                    <a href="noticias.html"><ButtonkLerMais type="button" className="btn btn-big-lermais">Ver Mais</ButtonkLerMais></a>
                </div>
            </div>
        </div> 
    */}
</section>
    );
}