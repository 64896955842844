import React from "react";
import { useState, useEffect } from "react";
import $ from 'jquery';
import jQuery from 'jquery';

//import '//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js';

//<script type='text/javascript' src='//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'></script>

import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha'

import './zona6newsletter.css';
//import "//cdn-images.mailchimp.com/embedcode/classic-10_7_dtp.css"

import NewsletterForm from "../NewsletterForm"

export default function Zona6Newsletter() {
    const [emailNews, setEmailNews] = useState('')
    const [loadedCaptcha, setLoadedCaptcha] = useState(false)
    const [captchaText, setCaptchaText] = useState('')

    useEffect(() => {
        if (emailNews !== '') {
            if (!loadedCaptcha) {
                setLoadedCaptcha(true);
                loadCaptchaEnginge(6);
            }
        } else {
            setLoadedCaptcha(false)
        }
        /*
            
                let divB =document.getElementById("exampleModal")
                divB.addEventListener('hidden.bs.modal', function(event){
                   alert("closed")
                });
                alert(divB.id)
                divB.addEventListener('shown.bs.modal', function(event){
                   
                   debugger;
                   fetch("http://eepurl.com/h6nlWj")
                                   .then(res => {
                                       debugger;
                                       alert(res)
                                   });
                 });
            
            */

    }, [emailNews, loadedCaptcha])

    function handleSubescrever(e) {
        e.preventDefault();
        if (!loadedCaptcha) return;
        //validar o capctha
        let user_captcha_value = captchaText;

        if (validateCaptcha(user_captcha_value, false) == true) {
            alert('Captcha Matched');
        }

        else {
            alert('Captcha Does Not Match');
        }
        /*
             //teste0
             
             //teste1
             (function($){ 
                window.fnames = new Array(); 
                window.ftypes = new Array();
                window.fnames[0]='EMAIL';window.ftypes[0]='email';
                window.fnames[1]='FNAME';window.ftypes[1]='text';window.fnames[2]='LNAME';
                window.ftypes[2]='text';window.fnames[3]='ADDRESS';window.ftypes[3]='address';
                window.fnames[4]='PHONE';window.ftypes[4]='phone';window.fnames[5]='BIRTHDAY';window.ftypes[5]='birthday';
             }(jQuery));var $mcj = jQuery.noConflict(true);
            
            */
    }
    return (
        <section>
            <div className="container-fluid newsletter">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2 style={{fontWeight:'600'}}>Mantenha-se a Par de Todas as Notícias e Novidades </h2>
                            <h4 style={{fontWeight:'500'}}>Subscreva com o seu e-mail para receber todas as notícias em primeira mão</h4>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-4 col-sm-4 col-lg-2">
                            <div className="row justify-concent-center">
                                {/*<div className="col-12">
                                    <form className="d-flex" onSubmit={(e) => { e.preventDefault(); }}>
                                        <input className="form-control me-2" style={{ maxHeight: "50px", borderWidth: "2px", borderColor: "#1ABFD5" }}
                                            type="search" placeholder="Email" aria-label="Search" onChange={(e) => { setEmailNews(e.target.value) }} />
                                        <button type="submit" style={{ border: "none" }} className="btn btn-primary" onClick={handleSubescrever}>Subscrever</button>
                                    </form>
                                </div>
                                <div className="col-12">
                                    <div className="captcha" style={{ display: emailNews === '' ? 'none' : 'flex' }}>
                                        <LoadCanvasTemplate />
                                        <input id="captcha_text" className="form-control me-2" style={{ maxHeight: "50px", borderWidth: "2px", borderColor: "#1ABFD5" }}
                                            type="search" placeholder="" onChange={(e) => { setCaptchaText(e.target.value) }} />
                                    </div>
                                </div>*/}
                                <button type="button" className="btn" data-bs-toggle="modal" data-bs-target="#exampleModal" style={{paddingBottom:"10px",paddingTop:"10px",backgroundColor:"#1ABFD5",color:"white",fontWeight:'600'}}>
                                    Subscrever
                                </button>
                            </div>



                        </div>

                    </div>
                </div>
            </div>



            {/*<!-- Modal -->*/}
            <div className="modal fade " id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog  modal-xl  modal-dialog-centered ">
                    <div className="modal-content">
                        {/*<div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Subscrever</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>*/}
                        <div className="modal-body" id="ConteudoNewsletterSubscription">
                            <div className="" style={{ width: "100%", minHeight: "520px" }}>
                                <iframe className="" style={{ width: "100%", minHeight: "520px" }}
                                    src={`http://eepurl.com/h6nlWj`}
                                    title="Subscrever"
                                    allowFullScreen
                                ></iframe>
                            </div>
                        </div>
                        {/*<div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary">Save changes</button>
                    </div>*/}
                    </div>
                </div>
            </div>
        </section>

    );
}