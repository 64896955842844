import React, { useEffect, useState } from "react";


import { useParams, useNavigate, useLocation } from "react-router-dom";

import BreadCrumbs from "../../../components/BreadCrumbs";
import NextPrevLine from "../../../components/NextPrevLine";

import Loading from '../../../components/Loading'
import ListaDeLinks from "../../../components/ListadeLinks";
import Share from "../../../components/Share";
import './detalhe.css';
import img_exames from '../../../images/images_default/exames_listagem.jpg';
import api, { apiEndpoint, siteURL, siteURLShare, mseErroComunicacao } from "../../../services/api";

import Erro from '../../../pages/Erro'


import { Helmet } from "react-helmet-async";
import { toast } from 'react-toastify';
export default function ActividadesDetalhe() {
    const location = useLocation();
    const { slug } = useParams();
    const navigate = useNavigate()
    const [breadCrumbsData, setBreadCrumbsData] = useState([
        {
            nome: "Home",
            url: "/"
        },
        {
            nome: "Avaliaçao externa",
            url: "/avaliacao-externa"
        }

    ]);

    const [nextPrevData, setNextPrevData] = useState(
        {
            prevName: null,
            prevUrl: null,
            nextName: null,
            nextUrl: null
        }
    );

    const [data, setData] = useState([]);
    const [listaLinks, setListaLinks] = useState([]);
    const [loading, setLoading] = useState(true)
    const [error404, setError404] = useState(false)

    useEffect(() => {

        async function loadData() {
            await api.get(`/pagina/avaliacao-externa/${slug}`).then(response => {
                //console.log("response",response,typeof(response.data) )
                if (typeof (response.data) == 'object' && response.data.status_code === 404) {

                    setError404(true);

                }
                else if (response.data.length > 0) {
                    //1 NextPrevLink
                    setData(response.data[1]);
                    setListaLinks(response.data[2]);
                    setNextPrevData({
                        prevName: response.data[0].previous_name,
                        prevUrl: response.data[0].previous_url,
                        nextName: response.data[0].next_name,
                        nextUrl: response.data[0].next_url
                    })

                    //2
                    setBreadCrumbsData(
                        [{
                            nome: "Home",
                            url: "/"
                        },
                        {
                            nome: "Alunos",
                            url: null
                        },
                        {
                            nome: "Avaliaçao externa",
                            url: "/avaliacao-externa"
                        },
                        {
                            nome: response.data[1].title,
                            url: response.data[1].url
                        }
                        ]);
                }
                setLoading(false);
            }).catch(err => {
                //toast.error(mseErroComunicacao);
                setTimeout(function () {
                    navigate("/erro-comunicacao");
                }, 5000);
            });



        }

        loadData();


        //console.log(data)
    }, [slug]);


    if (error404 === true) {

        return (
            <>
                <Erro></Erro>
            </>
        );
    }

    function tratadata(data) {
        var options = { day: 'numeric', month: 'long', year: 'numeric' };
        var date = new Date(data);
        let data_str = date.toLocaleDateString("pt-pt", options).toString().replace(".", "");
        options = { month: 'long' };
        var mes1 = date.toLocaleDateString("pt-pt", options).toString().replace(".", "");
        var mes2 = mes1.charAt(0).toUpperCase() + mes1.slice(1);
        //console.log("mes2=",mes2)

        data_str = data_str.replace(mes1, mes2);
        return data_str
    }
    return (
        <section className="content">
            {loading === true && (<Loading></Loading>)}

            {loading === false && (


                <>
                    <Helmet>
                        <title>{`Agrupamento de Escolas de Monte da Ola ${'>'} ${data.title}`}</title>
                        <meta name="description" content={`${data.body.replace(/<[^>]+>/g, '')}`} />
                        <meta name="keywords" content={`Website do Agrupamento de Escolas de Monte da Ola,${data.title}`} />
                        {/*facebook*/}
                        <meta name="og:title" content={data.title} />
                        <meta name="og:url" content={siteURLShare + location.pathname} />
                        <meta name="og:description" content={`${data.body.replace(/<[^>]+>/g, '').substring(0, 200)}`} />
                        <meta name="og:image" content={data.image} />
                        <meta name="og:type" content="website" />
                        {/*twitter*/}
                        <meta name="twitter:card" content="Agrupamento de Escolas de Monte da Ola" />
                        <meta name="twitter:title" content={data.title} />
                        <meta name="twitter:description" content={`${data.body.replace(/<[^>]+>/g, '').substring(0, 200)}`} />
                        <meta name="twitter:site" content={siteURLShare + location.pathname} />
                        <meta name="twitter:image" content={data.image} />
                        <meta name="twitter:creator" content="Agrupamento de Escolas de Monte da Ola" />
                    </Helmet>
                    <div className="container detalhe">
                        <BreadCrumbs data={breadCrumbsData} />
                    </div>
                    {/* Titulo */}
                    <div className="container detalhe">
                        <div className="row ligacao justify-content-left">
                            <div className="col-12">
                                <h1>{data.title}</h1>
                                <p>{tratadata(data.publishDate)} - {data.author}</p>
                                <div className="row justify-content-left">
                                    <div className="col-2">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* corpo detalhe */}
                    <div className="container detalhe">
                        <div className="row justify-content-center">
                            <div className="col-12 col-sm-12 col-md-8 col-lg-8 artigo-info">
                                <div dangerouslySetInnerHTML={{ __html: data.body }}>
                                </div>
                                <ListaDeLinks listaLinks={listaLinks}></ListaDeLinks>
                                <div style={{float:"right"}}><Share id="share" media={data.image} style={{float:"right"}}></Share></div>
                                {/*corpo de texto do item inicio*/}

                                {/*corpo de texto do item fim*
                <button className="btn btn-share" type="button"></button>    */}
                            </div>
                            {/*img do item */}
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                                <div className="image-artigo">
                                    <img src={data.image===null ? img_exames : data.image} className="d-block w-100" alt="img-m" />
                                </div>
                            </div>
                            {/* /img do item */}

                        </div>

                    </div>
                    <NextPrevLine data={nextPrevData} />

                    <div className="container detalhe">
                        <div className="row justify-content-center">
                            <div className="col-12 col-sm-12 justify-content-center" style={{ display: "flex" }}>
                                <button id="btn-voltar1" type="button" style={{ display: "flex" }} className="btn btn-voltar" onClick={(e) => { navigate(-1); }}>
                                    Voltar
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </section>
    );
}